/* header.css */
.navbar-nav .nav-link:hover {
  color: #ffffff !important; /* Change text color on hover */
  background-color: #3F556B !important; /* Change background color on hover */
}

.navbar-nav .nav-link.active {
  color: #000000; /* Change text color on active */
  background-color: #985421; /* Change background color on active */
}


@media (max-width: 280px) and (max-height: 653px) {
  .kelelogo {
    height: 50px !important;

  }
}