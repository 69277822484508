.hero-img{
    margin-top: 20px;
    
}


.swinging-link {
    animation: swing 1s infinite;
  }
  .rectangle-overlay {
    position: absolute;
    /* top: 250px; Adjust this value to change the vertical position */
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.8); /* Adjust the opacity and color */
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    width: 230px;
    height: 50px;
}

  @keyframes swing {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0);
    }
  }

@media screen and (max-width: 640px) {

    .hero-container{
        height: 120vh;
    }
  .hero-col-1{
    padding-top: 120px;
  }
   
    .hero-img{
        padding-top: 20px;
        width: 100%;
        height: 100%;
      
    }

    .aboutimg{
        width: auto;
        height:200px;
    }

    .about-content{
        margin-left: 20px;
        margin-right: 20px;
    }

    .work-content{
        margin-left: 20px;
        margin-right: 20px;
      
    }

    .workimg{
        width: auto;
        height:200px;
      
    }
    .who-content{
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        margin-right: 20px; 
    }
   
    .home-card{
        justify-content: center;
        align-items: center;
        padding-left: 50px;
    }
}

/* Media query for iPhone SE (375 by 667) */
@media only screen and (max-width: 375px) and (max-height: 667px) {
    /* Add your CSS rules specific to iPhone SE here */
    .hero-container {
        padding: 10px;
        padding-top: 20px;
    }
    .hero-col-1 {
       
        max-height: 50px;
        max-width: 100vh;
    }
    .hero-img {
        display: none;
    }
    .rectangle-overlay{
        display: none;
    }
}

/* Media query for Galaxy Fold (280 by 653) */
@media only screen and (max-width: 280px) and (max-height: 653px) {
    /* Add your CSS rules specific to Galaxy Fold here */
  
    .hero-container{
        margin-top: -50px;
        height: 90vh;
    }
    .hero-col-1,
    .hero-col-2 {
        max-height: 50px;
        max-width: 100vh;
    }
    .hero-img {
        display: none;
    }
     .rectangle-overlay{
        display: none;
    }
    .homepage-left{
        margin-right: 35px;
    }
}

@media only screen 
  and (min-device-width: 360px) 
  and (max-device-width: 740px) 
  and (-webkit-min-device-pixel-ratio: 4)
  and (orientation: portrait) {
  /* Styles for Samsung S8+ */
  .hero-img {
    display: none;
}
.rectangle-overlay{
    display: none;
}
}

